import {Box, Card, CardContent, Grid, Typography} from "@mui/material"
import React from "react"

const FeatureCard = ({icon, title, description}) => {
  return (
    <Grid sx={{p: {xs: "0.5rem", lg: "1rem"}}} item xs={12} md={6} lg={3}>
      <Card
        sx={{
          textAlign: "center",
          transition: "box-shadow 0.3s",
          "&:hover": {
            boxShadow: 6
          }
        }}
      >
        <CardContent>
          <Box sx={{mb: 2, display: "flex", justifyContent: "center", color: "primary.main"}}>
            {icon}
          </Box>
          <Typography variant="h6" component="h3" sx={{mb: 1, fontWeight: "bold"}}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default FeatureCard

import {Icon} from "@iconify/react"
import {AppBar, Box, Container, Link, styled, Toolbar} from "@mui/material"
import React from "react"
import mainLogo from "../../../../assets/images/edrak.png"

// Styled components
const StyledLink = styled(Link)(({theme}) => ({
  color: theme.palette.text.secondary,
  textDecoration: "none",
  transition: "color 0.2s",
  "&:hover": {
    color: theme.palette.primary.main
  }
}))

const Navbar = () => {
  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(8px)",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
      }}
    >
      <Container sx={{px: "1rem", maxWidth: {xl: "1536px"}}}>
        <Toolbar sx={{py: "1rem"}} disableGutters>
          <Box component="img" src={mainLogo} alt="إدراك" sx={{height: 32}} />
          <Box sx={{marginLeft: "auto", display: "flex", gap: 4}}>
            <StyledLink href="#">
              <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                <Icon icon="material-symbols:home-outline" />
                الرئيسية
              </Box>
            </StyledLink>
            <StyledLink href="#features">
              <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                <Icon icon="material-symbols:featured-play-list-outline" />
                المميزات
              </Box>
            </StyledLink>
            <StyledLink href="#pricing">
              <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                <Icon icon="material-symbols:payments-outline" />
                الأسعار
              </Box>
            </StyledLink>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Navbar

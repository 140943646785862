import edrakApi from "../clients/edrak/api"

export const signup = params => edrakApi.post("/api/register", params).then(client => client)

export const login = params => edrakApi.post("/api/login", params).then(client => client)

export const verifyOtp = params => edrakApi.post("/api/auth", params).then(client => client)

export const getUser = params => edrakApi.get("/api/user").then(client => client)

export const sendCode = params => edrakApi.post("/api/request-edit", params).then(client => client)

export const updateUser = (uuid, params) =>
  edrakApi.patch(`/api/user/${uuid}`, params).then(client => client)

export const getUsers = (page, page_size, search) => {
  const url = `/api/users?page=${page}&page_size=${page_size}${search ? `&search=${encodeURIComponent(search)}` : ""}`
  return edrakApi.get(url).then(client => client)
}

export const deleteUser = uuid => edrakApi.delete(`/api/user/${uuid}`).then(client => client)

export const usageSummary = param => edrakApi.get(`/api/users/summary`).then(client => client)

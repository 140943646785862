import {lazy} from "react"
import {Chatbot as path} from "../RoutesEnum"

const Chatbot = [
  {
    path: `/${path}`,
    component: lazy(() => import("../../views/pages/chatbot/index")),
    exact: true,
    meta: {
      withFooter: false,
      withTopBar: false
    }
  }
]

export default Chatbot

// ** Toolkit imports
import {configureStore} from "@reduxjs/toolkit"

import auth from "../reducers/authentication"
import caseComparison from "../reducers/caseComparision"
import chat from "../reducers/chat"
import layout from "../reducers/layout"
import settings from "../reducers/settings"
import subscriptions from "../reducers/subscriptions"
export const store = configureStore({
  reducer: {
    layout,
    settings,
    chat,
    auth,
    subscriptions,
    caseComparison
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false})
})

import {Icon} from "@iconify/react"
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from "@mui/material"
import React from "react"
import {useTranslation} from "react-i18next"

const PricingCard = ({
  title,
  price,
  features,
  featured = false,
  selected = false,
  onSubscribe = () => {}
}) => {
  const {t} = useTranslation()
  return (
    <Paper
      elevation={3}
      sx={{
        position: "relative",
        p: "2rem",
        transition: "transform 0.3s ease",
        transform: "translateY(0)",
        "&:hover": {
          transform: featured ? "translateY(-16px)" : "translateY(-8px)"
        },
        border: featured ? 2 : 0,
        borderColor: "primary.main",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Box>
        {featured && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <Typography
              variant="caption"
              sx={{
                bgcolor: "primary.main",
                color: "white",
                px: 2,
                py: 0.5,
                borderRadius: 50
              }}
            >
              {selected ? t("subscribed") : t("most_popular")}
            </Typography>
          </Box>
        )}

        <Typography variant="h4" fontWeight="bold" textAlign="center" mb={2}>
          {title}
        </Typography>

        <Box textAlign="center" mb={3}>
          <Typography component="span" variant="h3" fontWeight="bold">
            {price}
          </Typography>
          <Typography component="span" color="text.secondary">
            {t("monthly")}
          </Typography>
        </Box>

        <List sx={{mb: 4}}>
          {features.map((feature, index) => (
            <ListItem key={index} sx={{py: 1}}>
              <ListItemIcon sx={{minWidth: 40}}>
                <Icon icon="mdi:check" color="#2196f3" width="20" height="20" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Button
        onClick={onSubscribe}
        fullWidth
        variant={selected ? "contained" : "outlined"}
        sx={{
          py: 1.5,
          fontWeight: "bold"
        }}
      >
        {t(selected ? "subscribed" : "subscribe")}
      </Button>
    </Paper>
  )
}

export default PricingCard

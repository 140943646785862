const DataGrid = () => {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: ({theme}) => ({
          border: 0,
          borderBottom: `1px solid ${theme.palette.divider}`,
          color: theme.palette.text.primary,
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none"
          }
        }),
        toolbarContainer: ({theme}) => ({
          paddingRight: `${theme.spacing(6)} !important`,
          paddingLeft: `${theme.spacing(3.25)} !important`
        }),
        columnHeaders: ({theme}) => ({
          backgroundColor: theme.palette.customColors.tableHeaderBg,
          "& .MuiDataGrid-row--borderBottom": {
            background: "none !important"
          }
        }),
        columnHeader: ({theme}) => ({
          "&:not(.MuiDataGrid-columnHeaderCheckbox)": {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            "&:first-of-type": {
              paddingLeft: theme.spacing(6)
            }
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(6)
          }
        }),
        columnHeaderCheckbox: {
          maxWidth: "58px !important",
          minWidth: "58px !important"
        },
        columnHeaderTitleContainer: {
          padding: 0
        },
        columnHeaderTitle: ({theme}) => ({
          fontWeight: 500,
          letterSpacing: "1px",
          textTransform: "uppercase",
          fontSize: theme.typography.body2.fontSize
        }),
        columnSeparator: ({theme}) => ({
          color: theme.palette.divider
        }),
        row: {
          "&:last-child": {
            "& .MuiDataGrid-cell": {
              borderBottom: 0
            }
          }
        },
        cell: ({theme}) => ({
          borderColor: theme.palette.divider,
          alignContent: "center",
          "&:not(.MuiDataGrid-cellCheckbox)": {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            "&:first-of-type": {
              paddingLeft: theme.spacing(6)
            }
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(6)
          },
          "&:focus, &:focus-within": {
            outline: "none"
          }
        }),
        cellCheckbox: {
          maxWidth: "58px !important",
          minWidth: "58px !important"
        },
        editInputCell: ({theme}) => ({
          padding: 0,
          color: theme.palette.text.primary,
          "& .MuiInputBase-input": {
            padding: 0
          }
        }),
        footerContainer: ({theme}) => ({
          borderTop: `1px solid ${theme.palette.divider}`,
          "& .MuiTablePagination-toolbar": {
            paddingLeft: `${theme.spacing(4)} !important`,
            paddingRight: `${theme.spacing(4)} !important`
          },
          "& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel": {
            color: theme.palette.text.primary
          }
        }),
        selectedRowCount: ({theme}) => ({
          margin: 0,
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4)
        })
      }
    }
  }
}

export default DataGrid

// ** MUI Imports
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"

// ** Icon Imports
import Icon from "@src/@core/components/icon"

// ** Components
import ModeToggler from "@src/@core/layouts/components/shared-components/ModeToggler"
import LanguageDropdown from "../../../@core/layouts/components/shared-components/LanguageDropdown"

const AppBarContent = props => {
  // ** Props
  const {hidden, toggleNavVisibility, modeToggler} = props

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Box className="actions-left" sx={{mr: 2, display: "flex", alignItems: "center"}}>
        {hidden ? (
          <IconButton color="inherit" sx={{ml: -2.75}} onClick={toggleNavVisibility}>
            <Icon fontSize="1.5rem" icon="tabler:menu-2" />
          </IconButton>
        ) : null}
        {/* {<CompanySwitcher />} */}

        {modeToggler && <ModeToggler />}
      </Box>
      <Box className="actions-right" sx={{display: "flex", alignItems: "center"}}>
        <LanguageDropdown />
        {/* <UserDropdown /> */}
      </Box>
    </Box>
  )
}

export default AppBarContent

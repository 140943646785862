// ** MUI Theme Provider
import {deepmerge} from "@mui/utils"

// ** Theme Override Imports
import breakpoints from "./breakpoints"
import overrides from "./overrides"
import palette from "./palette"
import shadows from "./shadows"
import spacing from "./spacing"
import typography from "./typography"

const themeOptions = (settings, overrideMode) => {
  // ** Vars
  const {skin, mode, direction, themeColor} = settings

  const mergedThemeConfig = {
    breakpoints: breakpoints(),
    direction,
    components: overrides(settings),
    palette: palette(mode === "semi-dark" ? overrideMode : mode, skin),
    ...spacing,
    shape: {
      borderRadius: 6
    },
    cardbackground: "#F1F8FF",
    mixins: {
      toolbar: {
        minHeight: 64
      }
    },
    shadows: shadows(mode === "semi-dark" ? overrideMode : mode),
    typography
  }

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...(mergedThemeConfig.palette
          ? mergedThemeConfig.palette[themeColor]
          : palette(mode === "semi-dark" ? overrideMode : mode, skin).primary)
      }
    }
  })
}

export default themeOptions

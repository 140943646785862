// ** Redux Imports
import {createSlice} from "@reduxjs/toolkit"

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    displayNotFound: false,
    showAppErrorPage: false,
    setupIncomplete: false,
    showSetupGuide: false
  },
  reducers: {
    handleDisplayNotFoundPage: (state, action) => {
      state.displayNotFound = action.payload
    },
    setAppErrorPage: (state, action) => {
      state.showAppErrorPage = action.payload
    },
    handleSetupIncomplete: (state, action) => {
      state.setupIncomplete = action.payload
    },
    setShowSetupGuide: (state, action) => {
      state.showSetupGuide = action.payload
    }
  }
})

export const {
  handleDisplayNotFoundPage,
  setAppErrorPage,
  handleSetupIncomplete,
  setShowSetupGuide
} = layoutSlice.actions

export default layoutSlice.reducer

// ** Redux Imports
import {createSlice} from "@reduxjs/toolkit"

export const layoutSlice = createSlice({
  name: "auth",
  initialState: {
    userData: {}
  },
  reducers: {
    handleLogout: (state, action) => {
      state.userData = {}
    },
    updateCurrentUser: (state, action) => {
      state.userData = action.payload
    }
  }
})

export const {handleLogout, updateCurrentUser} = layoutSlice.actions

export default layoutSlice.reducer

import {lazy} from "react"
import {Login as path} from "../RoutesEnum"

const Login = [
  {
    path: `/${path}`,
    component: lazy(() => import("../../views/pages/login")),
    exact: true,
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  }
]

export default Login

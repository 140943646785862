import {Icon} from "@iconify/react"
import {Box, Button, Container, Grid, Typography} from "@mui/material"
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {getSubscriptions} from "../../../api/payment"
import mainLogo from "../../../assets/images/edrak.png"
import {updateSubscriptions} from "../../../redux/reducers/subscriptions"
import {LandingPage, Signup, Subscription} from "../../../router/RoutesEnum"
import FeatureCard from "./components/FeatureCard"
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"
import PricingCard from "./components/PricingCard"

const Intro = props => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(({subscriptions}) => subscriptions)
  const {subscription_id} = useSelector(({auth}) => auth.userData)
  const history = useHistory()
  useEffect(() => {
    if (!subscriptions.length) {
      getSubscriptions().then(({data}) => {
        dispatch(updateSubscriptions(data?.subscriptions))
      })
    }
  }, [subscriptions, dispatch])

  return (
    <Box sx={{minHeight: "100vh", bgcolor: "grey.50", fontFamily: "Tajawal"}} dir="rtl">
      <Navbar />

      <Box
        component="header"
        sx={{bgcolor: "white", color: "text.primary", pt: "8rem", pb: "5rem"}}
      >
        <Container>
          <Box sx={{textAlign: "center", maxWidth: "4xl", mx: "auto"}}>
            <Box
              component="img"
              src={mainLogo}
              alt="إدراك"
              sx={{height: 96, mx: "auto", mb: "2rem"}}
            />
            <Typography variant="h4" sx={{mb: "1rem", color: "text.secondary"}}>
              مساعدك القانوني الذكي
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: "2rem",
                maxWidth: "lg",
                mx: "auto",
                lineHeight: 1.7,
                color: "text.secondary"
              }}
            >
              منصة متكاملة لمساعدة المحامين في إدارة قضاياهم وتحسين كفاءة عملهم
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={LandingPage}
              sx={{px: "4rem", py: "1rem"}}
              endIcon={<Icon icon="lucide:arrow-right" className="rtl:rotate-180" />}
            >
              ابدأ الآن مجاناً
            </Button>
          </Box>
        </Container>
      </Box>

      <Box component="section" id="features" sx={{py: "5rem", bgcolor: "grey.50"}}>
        <Container sx={{maxWidth: {xl: "1536px"}}}>
          <Typography variant="h3" sx={{textAlign: "center", mb: "3rem", fontWeight: "bold"}}>
            مميزات المنصة
          </Typography>
          <Grid container>
            <FeatureCard
              icon={<Icon icon="lucide:scale" width={32} height={32} />}
              title="إدارة القضايا"
              description="تنظيم وإدارة جميع القضايا بكفاءة عالية"
            />

            <FeatureCard
              icon={<Icon icon="lucide:gavel" width={32} height={32} />}
              title="نماذج قانونية"
              description="مكتبة شاملة من النماذج القانونية الجاهزة"
            />

            <FeatureCard
              icon={<Icon icon="lucide:book-open" width={32} height={32} />}
              title="مكتبة قانونية"
              description="مراجع وأحكام قضائية محدثة باستمرار"
            />

            <FeatureCard
              icon={<Icon icon="lucide:shield" width={32} height={32} />}
              title="حماية البيانات"
              description="أمان وخصوصية عالية لجميع المعلومات"
            />
          </Grid>
        </Container>
      </Box>

      <Box component="section" id="pricing" sx={{py: "5rem", bgcolor: "white"}}>
        <Container sx={{maxWidth: {xl: "1536px"}}}>
          <Typography variant="h3" sx={{textAlign: "center", mb: "3rem", fontWeight: "bold"}}>
            باقات الاشتراك
          </Typography>
          <Grid container spacing={2}>
            {subscriptions.map(subscription => (
              <Grid
                key={subscription.uuid}
                sx={{p: {xs: "0.5rem", lg: "1rem"}}}
                item
                xs={12}
                md={4}
                onClick={() => {
                  if (subscription_id) {
                    history.push(Subscription)
                  } else {
                    history.push(Signup)
                  }
                }}
              >
                <PricingCard
                  title={t(subscription.name)}
                  price={subscription.price}
                  featured={
                    !subscription_id
                      ? subscription.name === "Pro"
                      : subscription.id === subscription_id
                  }
                  selected={subscription.id === subscription_id}
                  features={subscription.detail.split(", ")}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Footer />
    </Box>
  )
}
export default Intro

import {lazy} from "react"
import {LandingPage as path} from "../RoutesEnum"

const LandingPage = [
  {
    path: `/${path}`,
    component: lazy(() => import("../../views/pages/landingPage")),
    exact: true,
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  }
]

export default LandingPage

import lazyLoader from "../../ComponentLoader"
import {NotAuthorized as path} from "../RoutesEnum"

const MiscRoutes = [
  {
    path: `/${path}`,
    component: lazyLoader(() => import("../../pages/misc/NotAuthorized")),
    layout: "BlankLayout",
    meta: {
      publicRoute: true
    }
  }
]

export default MiscRoutes

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import DefaultPalette from "../../theme/palette"

const FallbackSpinner = ({sx, logo = false}) => {
  const colors = DefaultPalette()

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        ...sx
      }}
    >
      {logo && (
        <svg
          width={82}
          height={56.375}
          viewBox="0 0 39 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.2877 7.50121H18.7109V2.86816H20.2877V7.50121Z" fill="#FADC3C" />
          <path
            d="M26.6743 6.20166H12.3257C5.80502 6.20166 0.5 11.3976 0.5 17.7843C0.5 20.7433 1.63921 23.4451 3.51004 25.4952L3.54 25.5207L5.51332 27.2226L5.64261 27.3338C7.1161 28.3268 8.82925 29.0017 10.678 29.2549C10.8317 29.3268 11.0044 29.3669 11.1857 29.3669H12.3257V32.8417C12.3257 33.2679 12.5646 33.6594 12.9478 33.8617C13.3301 34.0633 13.7961 34.0432 14.1595 33.8085L21.0373 29.3669H26.6743C30.8945 29.3669 34.6046 27.1902 36.6978 23.9231L37.8322 21.622C38.2643 20.4197 38.5 19.1286 38.5 17.7843C38.5 11.3976 33.195 6.20166 26.6743 6.20166ZM35.1336 21.9286C34.6905 22.7949 34.1111 23.5841 33.4236 24.2698C31.7073 25.9848 29.3154 27.0504 26.6743 27.0504H20.6826C20.4508 27.0504 20.2245 27.1168 20.0314 27.2419L16.7407 29.3669L14.6909 30.6904V28.2087C14.6909 27.5693 14.1611 27.0504 13.5083 27.0504H12.3257C11.5042 27.0504 10.7064 26.9469 9.94639 26.7539C8.7244 26.4427 7.59859 25.8975 6.62021 25.1709C4.34021 23.4775 2.86515 20.7965 2.86515 17.7843C2.86515 12.6748 7.109 8.51819 12.3257 8.51819H26.6743C31.891 8.51819 36.1349 12.6748 36.1349 17.7843C36.1349 19.273 35.7746 20.6807 35.1336 21.9286Z"
            fill="#1C1C4B"
          />
          <path
            d="M19.5478 16.626C18.2436 16.626 17.1826 17.6652 17.1826 18.9425C17.1826 20.2198 18.2436 21.259 19.5478 21.259C20.8519 21.259 21.9129 20.2198 21.9129 18.9425C21.9129 17.6652 20.8519 16.626 19.5478 16.626Z"
            fill="#1C1C4B"
          />
          <path
            d="M27.5107 15.4678C26.2065 15.4678 25.1455 16.507 25.1455 17.7843C25.1455 19.0616 26.2065 20.1008 27.5107 20.1008C28.8148 20.1008 29.8758 19.0616 29.8758 17.7843C29.8758 16.507 28.8148 15.4678 27.5107 15.4678Z"
            fill="#1C1C4B"
          />
          <path
            d="M11.5839 15.4678C10.2798 15.4678 9.21875 16.507 9.21875 17.7843C9.21875 19.0616 10.2798 20.1008 11.5839 20.1008C12.888 20.1008 13.949 19.0616 13.949 17.7843C13.949 16.507 12.888 15.4678 11.5839 15.4678Z"
            fill="#1C1C4B"
          />
          <path
            d="M19.5526 0C18.2485 0 17.1875 1.03919 17.1875 2.31652C17.1875 3.59386 18.2485 4.63305 19.5526 4.63305C20.8568 4.63305 21.9178 3.59386 21.9178 2.31652C21.9178 1.03919 20.8568 0 19.5526 0Z"
            fill="#FADC3C"
          />
        </svg>
      )}
      <CircularProgress sx={{color: colors.primary.main, mt: 6}} disableShrink />
    </Box>
  )
}

export default FallbackSpinner

import {setAppErrorPage} from "./redux/reducers/layout"
import {store} from "./redux/storeConfig/store"
import {lazy} from "react"

const LOADING_TIMEOUT = 10000

function componentLoader(lazyComponent, attemptsLeft = 2) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (attemptsLeft === 0) {
            store.dispatch(setAppErrorPage(true))
            return null
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, LOADING_TIMEOUT)
      })
  })
}

const lazyLoader = component => lazy(() => componentLoader(component))

export default lazyLoader

import {lazy} from "react"
import {Subscription as path} from "../RoutesEnum"

const Subscription = [
  {
    path: `/${path}`,
    component: lazy(() => import("../../views/pages/payment/SelectPlan")),
    exact: true,
    meta: {
      withFooter: false,
      withTopBar: false
    }
  },
  {
    path: `/${path}/update`,
    component: lazy(() => import("../../views/pages/payment/UpdateSubscriptionPlan")),
    exact: true,
    meta: {
      withFooter: false,
      withTopBar: false,
      action: "write",
      resource: "subscription"
    }
  }
]

export default Subscription

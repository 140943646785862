export const Chatbot = "chatbot"
export const Logout = "logout"
export const NotAuthorized = "not-authorized"
export const Signup = "signup"
export const Login = "login"
export const LandingPage = "get-started"
export const Payment = "payment"
export const PaymentUpdate = "payment-update"
export const Intro = "intro"
export const Subscription = "subscription"

// ** React Imports
import {Suspense} from "react"
import ReactDOM from "react-dom"

// ** Redux Imports
import {Provider} from "react-redux"
import {store} from "./redux/storeConfig/store"

// ** Intl, CASL & ThemeColors Context
import {AbilityContextProvider} from "./utility/context/Can"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner"

import "./configs/i18n"

// ** Global css styles
import "@src/styles/globals.css"

import "react-perfect-scrollbar/dist/css/styles.css"
import "./assets/scss/style.scss"

import {Toaster} from "react-hot-toast"
import ReactHotToast from "./styles/components/reactHotToast"

// ** Service Worker
import ThemeComponent from "./@core/theme/ThemeComponent"
import AppPermissions from "./AppPermissions"
import Router from "./router/Router"
import * as serviceWorker from "./serviceWorker"
import {CurrentUserContextProvider} from "./utility/context/CurrentUserContext"

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner logo={true} />}>
      <AppPermissions>
        <CurrentUserContextProvider>
          <AbilityContextProvider>
            <ThemeComponent>
              <Router />
              <ReactHotToast>
                <Toaster position={"top-left"} toastOptions={{className: "react-hot-toast"}} />
              </ReactHotToast>
            </ThemeComponent>
          </AbilityContextProvider>
        </CurrentUserContextProvider>
      </AppPermissions>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

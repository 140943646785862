// ** Third Party Import
import {useTranslation} from "react-i18next"

// ** Custom Components Imports
import OptionsMenu from "@src/@core/components/option-menu"
import {useState} from "react"
import Translations from "../../../../layouts/components/Translations"

const LanguageDropdown = () => {
  // ** Hook
  const {i18n} = useTranslation()
  const [availableLanguages, setAvailableLanguages] = useState([
    {
      code: "en",
      language: "English"
    },
    {
      code: "ar",
      language: "\u0639\u0631\u0628\u064a"
    }
  ])

  const handleLangItem = lang => {
    i18n.changeLanguage(lang)
    localStorage.setItem("i18nextLng", lang)
  }

  // const userLanguage = user?.language

  // useEffect(() => {
  //   if (userLanguage?.code) {
  //     handleLangItem(userLanguage.code)
  //   }
  // }, [userLanguage])

  const activeLanguage = i18n.language

  return (
    <OptionsMenu
      CustomButton={<Translations text="languages" />}
      menuProps={{sx: {"& .MuiMenu-paper": {mt: 4.25, minWidth: 130}}}}
      options={
        availableLanguages?.map(({code, language}) => ({
          text: language,
          menuItemProps: {
            sx: {py: 2},
            selected: activeLanguage === code,
            onClick: () => handleLangItem(code)
          }
        })) ?? []
      }
    />
  )
}

export default LanguageDropdown

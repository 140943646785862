import useMediaQuery from "@mui/material/useMediaQuery"

import Layout from "@src/@core/layouts/Layout"

// ** Navigation Imports
import VerticalNavItems, {bottomNavigation} from "@src/navigation/vertical"

import {useEffect} from "react"
import {useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {Subscription} from "../router/RoutesEnum"
import VerticalAppBarContent from "./components/vertical/AppBarContent"

const UserLayout = ({children, contentHeightFixed, layout, routerProps}) => {
  console.log("layout", layout)
  console.log("routerProps", routerProps)
  const hidden = useMediaQuery(theme => theme.breakpoints.down("lg"))
  const navItems = VerticalNavItems(true)
  const history = useHistory()
  const {userData} = useSelector(({auth}) => auth)

  useEffect(() => {
    if (userData?.uuid && !userData?.subscription_id && !userData.is_admin)
      history.push(Subscription)
  }, [userData, history])

  return (
    <Layout
      hidden={hidden}
      {...(routerProps?.meta && routerProps.meta)}
      contentHeightFixed={contentHeightFixed}
      {...(layout === "vertical" && {
        verticalLayoutProps: {
          navMenu: {navItems},
          navBottomMenu: {navItems: bottomNavigation(true)},
          appBar: {
            content: props => (
              <VerticalAppBarContent
                modeToggler={false}
                hidden={hidden}
                toggleNavVisibility={props.toggleNavVisibility}
              />
            )
          }
        }
      })}
      layout={layout}
    >
      {children}
    </Layout>
  )
}

export default UserLayout

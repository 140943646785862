import {toast} from "react-hot-toast"
import {Converter} from "showdown"
import {isHttpUri, isHttpsUri} from "valid-url"

const converter = new Converter()
export const mdToHtml = val => (val ? `${converter.makeHtml(val)}` : "")
export const htmlToMd = val => (val ? `${converter.makeMarkdown(val)}` : "")
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("edrak-token")

export const getUserData = () => JSON.parse(isUserLoggedIn())

export const generateUrl = (path, searchParams = {}, basePath) => {
  if (undefined === basePath) {
    basePath = `${window.location.protocol}//${window.location.host}`
  }
  const url = new URL(`${basePath}${path}`)
  for (const [k, v] of Object.entries(searchParams)) {
    url.searchParams.append(k, v)
  }
  return url.toString()
}

export const generateAdminUrl = (path, searchParams = {}) => {
  const basePath =
    import.meta.env.NODE_ENV === "development"
      ? import.meta.env.VITE_ADMIN_URL
      : window.location.origin
  return generateUrl(path, searchParams, basePath)
}

export const generateSubdomainUrl = (subDomain, path) =>
  `${import.meta.env.VITE_URL.replace("app", subDomain)}${path}`

export const getSubDomain = () => window.location.hostname.split(".")[0]

export const generateIRI = (resource, uuid) => `/api/${resource}/${uuid}`

export const objectLength = obj => (obj ? Object.keys(obj).length : 0)

export const formatBytes = bytes => {
  if (bytes >= 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`
  } else {
    return `${(bytes / 1024).toFixed(1)} KB`
  }
}

export const parseLines = lines =>
  lines
    .map(line => line.replace(/^data: /, "").replace(/\r/g, ""))
    .filter(line => line !== "" && line !== "[DONE]")

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const getIdsByTitles = (teamTitles, teams) => {
  const teamIds = []

  for (const title of teamTitles) {
    const matchingItem = teams.find(item => item.title === title)
    if (matchingItem) {
      teamIds.push(matchingItem.id)
    }
  }

  return teamIds
}

export const showError = (message, time) => toast.error(message, {duration: time ?? 5000})

export const showSuccess = (message, time) => toast.success(message, {duration: time ?? 5000})

export const validateEmail = email => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}
export const isArabic = () =>
  (navigator.language || navigator.userLanguage).toLowerCase().includes("ar")

export const convertHttpToHttps = url => url?.replace(/^http:/, "https:")

export const removeHttpOrHttps = url => url?.replace(/^https?:\/\//, "")

export const isLocalEnvironment = () => import.meta.env.NODE_ENV === "development"

export const readBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = error => reject(error)
  })
}

export const isNullOrUndefined = value => value === null || typeof value === "undefined"

export const getIntegrationImageUrl = integration => `images/${integration}.svg`

export const getFileExtension = fileName => {
  const lastDotIndex = fileName?.lastIndexOf?.(".")
  if (lastDotIndex !== -1) {
    return fileName.substring(lastDotIndex + 1)
  }
  return null
}

export function formatErrorMessage(inputString) {
  if (!inputString) {
    return ""
  }
  const lines = inputString.split("\n")
  const nonEmptyLines = lines?.filter(line => line.trim() !== "")

  return nonEmptyLines?.length > 1
    ? `<ul>${nonEmptyLines.map(line => `<li>${line.trim()}</li>`).join("")}</ul>`
    : nonEmptyLines?.[0]
}

export function isValidJsonString(str) {
  if (!str || typeof str !== "string") {
    return false
  }
  const e = str.trim()
  return e.startsWith("{") && e.endsWith("}")
}

export const urlValidation = (
  event,
  url,
  getTranslation,
  setUrlError = null,
  setUrlData = null
) => {
  event?.preventDefault()
  if (setUrlError) setUrlError()
  const pastedText = event ? (event.clipboardData || window.clipboardData).getData("text") : null
  const path = pastedText ?? url
  if (!path) return null

  if (!(path?.split(".").length >= 2 && path?.split(".")[1])) {
    setUrlError?.(getTranslation("Url_Not_Valid"))
    setUrlData?.(removeHttpOrHttps(path))
    return null
  }

  if (isHttpsUri(path)) {
    setUrlData?.(removeHttpOrHttps(path))
    return path
  } else if (isHttpUri(path)) {
    setUrlData?.(removeHttpOrHttps(path))
    return convertHttpToHttps(path)
  } else if (isHttpsUri(`https://${path}`)) {
    setUrlData?.(path)
    return `https://${path}`
  } else {
    setUrlError?.(getTranslation("Url_Not_Valid"))
    setUrlData?.(path)
    return null
  }
}

export const getContrastColor = themeColor => {
  const r = parseInt(themeColor.slice(1, 3), 16)
  const g = parseInt(themeColor.slice(3, 5), 16)
  const b = parseInt(themeColor.slice(5, 7), 16)
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
  return luminance > 128 ? "#000000" : "#ffffff"
}

export const removeCommentsAndTag = str =>
  str
    .replace(/<!--[\s\S]*?-->/g, "")
    .replace(/<br>/g, "")
    .replace(/\n\n\n/g, "")

export const formatNumber = num => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, "")}M`
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, "")}K`
  }
  return num
}

export const formatTime = sec => {
  if (sec >= 3600) {
    return `${Math.floor(sec / 3600)} h`
  } else if (sec >= 60) {
    return `${Math.floor(sec / 60)} m`
  }
  return `${sec} s`
}

export const isNotDevelopment = () => import.meta.env.NODE_ENV !== "development"

export const a11yProps = index => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`
})

export const slash = e => `/${e}`

export const widgetScript = (domain, uuid) => {
  if (uuid && domain) {
    return `<script>addWidget('${uuid}', '${domain}')</script>`
  } else if (domain) {
    return `<script type="text/javascript" src="${domain}/widget.js"></script>`
  }
}

const replaceDynamicText = text => {
  const pattern = /\[\[!\[(.*?)\]\((https:\/\/[^\)]+)\)\]\([^\)]+\)\]\(([^\)]+)\)/g

  if (pattern.test(text)) {
    const replacement = function (match, p1, p2, p3) {
      return `![${p1}](${p2})`
    }
    const newText = text.replace(pattern, replacement)
    return newText
  } else {
    return text
  }
}

export const replaceLinks = (content, attachments) => {
  let pattern
  let replacement
  attachments.forEach(({file_original_id, file_signin_url, file_type}) => {
    if (file_type && file_type === "file_path") {
      pattern = new RegExp(`!\\[(${file_original_id}), ([^\\]]*)\\]\\([^)]*\\)`, "g")
      replacement = `[$2](${file_signin_url})\n\n`
      if (!content.match(pattern)) {
        pattern = new RegExp(`!\\[(${file_original_id})\\]\\([^)]*\\)`, "g")
        replacement = `[![Download_Image](${file_signin_url})](${file_signin_url})`
      }
    } else {
      pattern = new RegExp(`!\\[(${file_original_id})\\]\\([^)]*\\)`, "g")
      replacement = `[![Download_Image](${file_signin_url})](${file_signin_url})`
    }
    if (content.match(pattern)) {
      content = content.replace(pattern, replacement)
    }
  })
  content = replaceDynamicText(content)
  return content
}

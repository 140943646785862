// ** React Imports
import {useContext} from "react"

// ** Component Imports
import {AbilityContext} from "@src/utility/context/Can"

const CanViewNavGroup = props => {
  // ** Props
  const {children, navGroup} = props

  // ** Hook
  const ability = useContext(AbilityContext)

  const checkForVisibleChild = arr => {
    return arr.some(i => {
      if (i.children) {
        return checkForVisibleChild(i.children)
      } else {
        return ability?.can(i.action, i.resource)
      }
    })
  }

  const canViewMenuGroup = item => {
    const hasAnyVisibleChild = item.children && checkForVisibleChild(item.children)
    if (!(item.action && item.resource)) {
      return hasAnyVisibleChild
    }

    return ability && ability.can(item.action, item.resource) && hasAnyVisibleChild
  }
  if (navGroup && navGroup.auth === false) {
    return <>{children}</>
  } else {
    return navGroup && canViewMenuGroup(navGroup) ? <>{children}</> : null
  }
}

export default CanViewNavGroup

// ** React Import
import {useEffect, useRef} from "react"

// ** Layout Components
import {saveLayout, saveNavCollapsed} from "@src/redux/reducers/settings"
import {useDispatch} from "react-redux"
import {useSettings} from "../hooks/useSettings"
import BlankLayout from "./BlankLayout"
import vertical from "./VerticalLayout"

const Layout = props => {
  // ** Props
  const {hidden, children, layout} = props
  const dispatch = useDispatch()
  const {settings} = useSettings()
  const getLayout = {
    BlankLayout,
    vertical
  }
  const CurrentLayout = getLayout[layout] ?? getLayout["vertical"]

  // ** Ref
  const isCollapsed = useRef(settings.navCollapsed)

  useEffect(() => {
    if (hidden) {
      if (settings.navCollapsed) {
        dispatch(saveNavCollapsed(false))
        dispatch(saveLayout("vertical"))
        isCollapsed.current = true
      }
    } else {
      if (isCollapsed.current) {
        dispatch(saveNavCollapsed(true))
        dispatch(saveLayout(settings.lastLayout))
        isCollapsed.current = false
      } else {
        if (settings.lastLayout !== settings.layout) {
          dispatch(saveLayout(settings.lastLayout))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  return <CurrentLayout {...props}>{children}</CurrentLayout>
}

export default Layout

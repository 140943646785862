import {Box, Link, styled, Typography} from "@mui/material"
import Translations from "../../../../../layouts/components/Translations"

const StyledCompanyName = styled(Link)(({theme}) => ({
  fontWeight: 500,
  textDecoration: "none",
  color: `${theme.palette.primary.main} !important`
}))

const FooterContent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Typography sx={{mr: 2, display: "flex", color: "text.secondary"}}>
        <Translations text="Powered_by" />
        <Typography sx={{ml: 1}} target="_blank" href="#" component={StyledCompanyName}>
          Edrak{" "}
        </Typography>
        <span style={{marginLeft: "5px"}}>
          <Translations text="COPYRIGHT" /> © {new Date().getFullYear()},{" "}
          <Translations text="All_Rights_Reserved" />
        </span>
      </Typography>
    </Box>
  )
}

export default FooterContent

import IconButton from "@mui/material/IconButton"
import {useDispatch} from "react-redux"
import Icon from "@src/@core/components/icon"
import {useSettings} from "@src/@core/hooks/useSettings"
import {saveMode} from "@src/redux/reducers/settings"

const ModeToggler = () => {
  const {settings} = useSettings()
  const dispatch = useDispatch()
  const handleModeChange = mode => {
    dispatch(saveMode(mode))
  }

  const handleModeToggle = () => {
    if (settings.mode === "light") {
      handleModeChange("dark")
    } else {
      handleModeChange("light")
    }
  }

  return (
    <IconButton color="inherit" aria-haspopup="true" onClick={handleModeToggle}>
      <Icon
        fontSize="1.625rem"
        icon={settings.mode === "dark" ? "tabler:sun" : "tabler:moon-stars"}
      />
    </IconButton>
  )
}

export default ModeToggler

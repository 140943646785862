import Spinner from "@src/@core/components/spinner"
import BlankLayout from "@src/@core/layouts/BlankLayout"
import UserLayout from "@src/layouts/UserLayout"
import React, {lazy, Suspense} from "react"
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import {isUserLoggedIn} from "../utility/Utils"
import Intro from "../views/pages/intro"
import {DefaultRoute, Routes} from "./routes"

const FinalRoute = props => {
  const route = props.route
  if (!isUserLoggedIn() && route.meta && !route.meta.publicRoute && !route.meta.authRoute) {
    return <Redirect to={"/"} />
  } else if (isUserLoggedIn() && route.meta && route.meta.authRoute) {
    return <Redirect to="/" />
  } else {
    return <route.component {...props} />
  }
}

const Router = () => {
  const DefaultLayout = "vertical"

  const Layouts = {
    BlankLayout: "BlankLayout",
    vertical: "vertical",
    FooterLayout: "FooterLayout"
  }

  const LayoutRoutesAndPaths = layout => {
    const LayoutRoutes = []
    const LayoutPaths = []

    Routes?.filter(route => {
      if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
        LayoutRoutes.push(route)
        LayoutPaths.push(route.path)
      }
    })
    return {LayoutRoutes, LayoutPaths}
  }

  const NotAuthorized = lazy(() => import("@src/pages/misc/NotAuthorized"))
  const NotFound = lazy(() => import("@src/pages/misc/NotFound"))
  const Error = lazy(() => import("@src/pages/misc/Error"))

  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      const {LayoutRoutes, LayoutPaths} = LayoutRoutesAndPaths(layout)
      const routerProps = {}
      return (
        <Route path={LayoutPaths} key={index}>
          <UserLayout routerProps={routerProps} layout={layout} contentHeightFixed={false}>
            <Switch>
              {LayoutRoutes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact === true}
                  render={props => {
                    // ** Assign props to routerProps
                    Object.assign(routerProps, {
                      ...props,
                      meta: route.meta
                    })
                    return (
                      <Suspense fallback={<Spinner />}>
                        <FinalRoute route={route} {...props} />
                      </Suspense>
                    )
                  }}
                />
              ))}
            </Switch>
          </UserLayout>
        </Route>
      )
    })
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (isUserLoggedIn() ? <Redirect to={DefaultRoute} /> : <Intro />)}
        />
        <Route exact path="/not-authorized" render={() => <NotAuthorized />} />
        <Route exact path="/error" render={() => <Error />} />

        {ResolveRoutes()}

        <Route
          path="*"
          render={() => (
            <BlankLayout>
              <NotFound />
            </BlankLayout>
          )}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default Router

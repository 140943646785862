// ** MUI Imports
import CssBaseline from "@mui/material/CssBaseline"
import GlobalStyles from "@mui/material/GlobalStyles"
import {ThemeProvider, createTheme, responsiveFontSizes} from "@mui/material/styles"
import {saveDirection} from "@src/redux/reducers/settings"

// ** Theme Config
import themeConfig from "@configs/themeConfig"

// ** Direction component for LTR or RTL
import Direction from "@src/layouts/components/Direction"

// ** Theme
import themeOptions from "./ThemeOptions"

// ** Global Styles
import {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch} from "react-redux"
import {useSettings} from "../hooks/useSettings"
import GlobalStyling from "./globalStyles"

const ThemeComponent = props => {
  // ** Props
  const {children} = props

  const {settings} = useSettings()

  const {i18n} = useTranslation()

  const dispatch = useDispatch()

  // ** Pass merged ThemeOptions (of core and user) to createTheme function
  //let theme = createTheme(themeOptions(settings, 'light'))
  let theme = createTheme(themeOptions(settings))

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  // ** Change html `lang` attribute when changing locale
  useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language)
    dispatch(saveDirection(i18n.dir()))
  }, [i18n.language])

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches
    if (isMobile) {
      document
        .getElementsByClassName("layout-wrapper")?.[0]
        ?.scrollIntoView({behavior: "smooth", block: "end"})
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme)} />
        {children}
      </Direction>
    </ThemeProvider>
  )
}

export default ThemeComponent

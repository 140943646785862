import {lazy} from "react"
import {Intro as path} from "../RoutesEnum"

const Intro = [
  {
    path: `/${path}`,
    component: lazy(() => import("../../views/pages/intro")),
    exact: true,
    layout: "BlankLayout"
  }
]

export default Intro

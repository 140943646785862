export default {
  spacing: factor => `${0.25 * factor}rem`
}

// const spacing = (factor) => {
//   // Define your breakpoints
//   const breakpoints = {
//     xs: 0,
//     vs: 365,
//     sm: 600,
//     md: 900,
//     lg: 1200,
//     xl: 1370,
//     xxl: 1536
//   }

//   // Define your spacing scale
//   const spacingScale = {
//     xs: 0.10,
//     vs: 0.15,
//     sm: 0.20,
//     md: 0.25,
//     lg: 0.25,
//     xl: 0.25,
//     xxl: 0.25
//   }

//   // Function to get the current breakpoint
//   const getCurrentBreakpoint = () => {
//     const width = window.innerWidth
//     if (width >= breakpoints.xl) return 'xl'
//     if (width >= breakpoints.lg) return 'lg'
//     if (width >= breakpoints.md) return 'md'
//     if (width >= breakpoints.sm) return 'sm'
//     return 'xs'
//   }

//   // Get the spacing value based on the current breakpoint
//   const breakpoint = getCurrentBreakpoint()
//   return `${spacingScale[breakpoint] * factor}rem`
// }

// export default spacing

// ** Redux Imports
import {createSlice} from "@reduxjs/toolkit"

export const slice = createSlice({
  name: "subscriptions",
  initialState: [],
  reducers: {
    updateSubscriptions: (state, action) => {
      return action.payload
    },
    updateSubscription: (state, action) => {
      const updatedState = state.map(subscription =>
        subscription.uuid === action.payload.uuid
          ? {...subscription, ...action.payload}
          : subscription
      )
      return updatedState
    }
  }
})
export const {updateSubscriptions, updateSubscription} = slice.actions
export default slice.reducer

import {lazy} from "react"
import {Payment, PaymentUpdate} from "../RoutesEnum"

const Payments = [
  {
    path: `/${Payment}`,
    component: lazy(() => import("../../views/pages/payment/index")),
    exact: true,
    meta: {
      withFooter: false,
      withTopBar: false
    }
  },
  {
    path: `/${PaymentUpdate}`,
    component: lazy(() => import("../../views/pages/payment/PaymentUpdate")),
    exact: true,
    meta: {
      withFooter: false,
      withTopBar: false
    }
  }
]

export default Payments

// ** Routes Imports
import Chatbot from "./Chatbot"
import Intro from "./Intro"
import LandingPage from "./LandingPage"
import Login from "./Login"
import MiscRoutes from "./Misc"
import Payments from "./payments"
import SecurityRoutes from "./Security"
import Signup from "./Signup"
import Subscription from "./Subscription"
// ** Document title
const TemplateTitle = "%s - Edrak"
const DefaultRoute = "chatbot"
// ** Merge Routes
const Routes = [
  ...SecurityRoutes,
  ...MiscRoutes,
  ...Chatbot,
  ...Signup,
  ...Login,
  ...LandingPage,
  ...Payments,
  ...Intro,
  ...Subscription
]

export {DefaultRoute, Routes, TemplateTitle}

const userAbility = user => {
  const userAbility = [
    {
      action: "read",
      subject: "default"
    }
  ]
  if (user) {
    userAbility.push({
      action: "write",
      subject: "chatbot"
    })
  }
  if (user?.is_admin) {
    userAbility.push({
      action: "write",
      subject: "subscription"
    })
  }
  return userAbility
}

export default userAbility

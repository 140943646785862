import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  paginatedSummaries: {},
  totalFiles: 0,
  filePage: 1
}

export const caseComparisonSlice = createSlice({
  name: "caseComparison",
  initialState: {...initialState},
  reducers: {
    addPaginatedSummaries: (state, action) => {
      const summariesWithUUID = action.payload.reduce((acc, summary) => {
        const {uuid} = summary
        if (uuid) {
          acc[uuid] = summary
        }
        return acc
      }, {})
      state.paginatedSummaries = {
        ...state.paginatedSummaries,
        ...summariesWithUUID
      }
    },
    setTotalFiles: (state, action) => {
      state.totalFiles = action.payload
    },
    setFilePage: (state, action) => {
      state.filePage = action.payload
    },
    resetPagination: state => {
      state = initialState
    }
  }
})

export const {addPaginatedSummaries, setTotalFiles, setFilePage, resetPagination} =
  caseComparisonSlice.actions

export default caseComparisonSlice.reducer

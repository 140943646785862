// ** React Import
import {useRef, useState} from "react"

// ** MUI Imports
import {Box, List} from "@mui/material"

import {createTheme, responsiveFontSizes, styled, ThemeProvider} from "@mui/material/styles"

// ** Third Party Components
import PerfectScrollbar from "react-perfect-scrollbar"

// ** Theme Config
import themeConfig from "@configs/themeConfig"

// ** Component Imports
import Drawer from "./Drawer"
import VerticalNavItems from "./VerticalNavItems"

// ** Theme Options
import themeOptions from "@src/@core/theme/ThemeOptions"

// ** Util Import
import {useSettings} from "@src/@core/hooks/useSettings"
import {hexToRGBA} from "@src/@core/utils/hex-to-rgba"

const StyledBoxForShadow = styled(Box)(({theme}) => ({
  top: 60,
  left: -8,
  zIndex: 2,
  opacity: 0,
  position: "absolute",
  pointerEvents: "none",
  width: "calc(100% + 15px)",
  height: theme.mixins.toolbar.minHeight,
  transition: "opacity .15s ease-in-out",
  background: `linear-gradient(${theme.palette.background.paper} ${
    theme.direction === "rtl" ? "95%" : "5%"
  },${hexToRGBA(theme.palette.background.paper, 0.85)} 30%,${hexToRGBA(
    theme.palette.background.paper,
    0.5
  )} 65%,${hexToRGBA(theme.palette.background.paper, 0.3)} 75%,transparent)`,
  "&.scrolled": {
    opacity: 1
  }
}))

const MenuItemTextMetaWrapper = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && {overflow: "hidden"})
}))

const Navigation = props => {
  // ** Props
  const {
    hidden,
    afterNavMenuContent,
    beforeNavMenuContent,
    navigationBorderWidth,
    navMenuContent: userNavMenuContent,
    collapsedNavWidth
  } = props

  // ** States
  const [navHover, setNavHover] = useState(false)
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])

  // ** Ref
  const shadowRef = useRef(null)

  // ** Var
  const {afterVerticalNavMenuContentPosition, beforeVerticalNavMenuContentPosition} = themeConfig
  const {settings} = useSettings()

  const navMenuContentProps = {
    ...props,
    navHover,
    groupActive,
    setGroupActive,
    currentActiveGroup,
    setCurrentActiveGroup
  }

  // ** Create new theme for the navigation menu when mode is `semi-dark`
  let darkTheme = createTheme(themeOptions(settings, "dark"))
  const {navCollapsed} = settings
  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    darkTheme = responsiveFontSizes(darkTheme)
  }

  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = ref => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect
      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect()

        return {...original, height: Math.floor(original.height)}
      }
    }
  }

  // ** Scroll Menu
  const scrollMenu = container => {
    if (beforeVerticalNavMenuContentPosition === "static" || !beforeNavMenuContent) {
      container = hidden ? container.target : container
      if (shadowRef && container.scrollTop > 0) {
        // @ts-ignore
        if (!shadowRef.current.classList.contains("scrolled")) {
          // @ts-ignore
          shadowRef.current.classList.add("scrolled")
        }
      } else {
        // @ts-ignore
        shadowRef.current.classList.remove("scrolled")
      }
    }
  }

  const ScrollWrapper = hidden ? Box : PerfectScrollbar

  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer
        {...props}
        navHover={navHover}
        setNavHover={setNavHover}
        navigationBorderWidth={navigationBorderWidth}
      >
        {/* <VerticalNavHeader {...props} navHover={navHover} /> */}
        {beforeNavMenuContent && beforeVerticalNavMenuContentPosition === "fixed"
          ? beforeNavMenuContent(navMenuContentProps)
          : null}
        {(beforeVerticalNavMenuContentPosition === "static" || !beforeNavMenuContent) && (
          <StyledBoxForShadow ref={shadowRef} />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between"
          }}
        >
          <Box sx={{position: "relative", overflow: "hidden"}}>
            {/* @ts-ignore */}
            <ScrollWrapper
              {...(hidden
                ? {
                    onScroll: container => scrollMenu(container),
                    sx: {height: "100%", overflowY: "auto", overflowX: "hidden"}
                  }
                : {
                    options: {wheelPropagation: false},
                    onScrollY: container => scrollMenu(container),
                    containerRef: ref => handleInfiniteScroll(ref)
                  })}
            >
              {beforeNavMenuContent && beforeVerticalNavMenuContentPosition === "static"
                ? beforeNavMenuContent(navMenuContentProps)
                : null}
              {userNavMenuContent ? (
                userNavMenuContent(navMenuContentProps)
              ) : (
                <List className="nav-items" sx={{pt: 0}}>
                  <VerticalNavItems
                    navHover={navHover}
                    groupActive={groupActive}
                    setGroupActive={setGroupActive}
                    currentActiveGroup={currentActiveGroup}
                    setCurrentActiveGroup={setCurrentActiveGroup}
                    {...props}
                  />
                </List>
              )}
              {afterNavMenuContent && afterVerticalNavMenuContentPosition === "static"
                ? afterNavMenuContent(navMenuContentProps)
                : null}
            </ScrollWrapper>
          </Box>
          <Box sx={{mb: 4}}>
            <List className="nav-items" sx={{pt: 0}}>
              <VerticalNavItems
                navHover={navHover}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
                {...props}
                verticalNavItems={props.verticalNavBottomItems}
              />
            </List>
          </Box>
        </Box>
        {afterNavMenuContent && afterVerticalNavMenuContentPosition === "fixed"
          ? afterNavMenuContent(navMenuContentProps)
          : null}
      </Drawer>
    </ThemeProvider>
  )
}

export default Navigation

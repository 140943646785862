import axios from "axios"

import NProgress from "nprogress"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig"
import {getUserData} from "../utility/Utils"

const requestInterceptor = () => [
  config => {
    NProgress.start()
    const userData = getUserData()
    if (userData && !config.headers.Authorization) {
      config.headers.Authorization = `${jwtDefaultConfig.tokenType} ${userData}`
    }
    return config
  },
  error => {
    NProgress.done()
    return Promise.reject(error)
  }
]

const responseInterceptor = [
  response => {
    NProgress.done()
    return response
  },
  error => {
    NProgress.done()
    return Promise.reject(error)
  }
]

function createApi(entrypoint) {
  const apiClient = axios.create({
    baseURL: entrypoint,
    timeout: 180000
  })

  apiClient.interceptors.request.use(...requestInterceptor())
  apiClient.interceptors.response.use(...responseInterceptor)

  return {
    get: async (url, config = {}) => await apiClient.get(url, config),
    post: async (url, data, config = {}) => await apiClient.post(url, data, config),
    put: async (url, data, config = {}) => await apiClient.put(url, data, config),
    patch: async (url, data, config = {}) => await apiClient.patch(url, data, config),
    delete: async (url, config = {}) => await apiClient.delete(url, config)
  }
}
export default createApi

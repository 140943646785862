const typography = {
  fontFamily: `"IBM Plex Sans Arabic", sans-serif`,
  fontSize: 13.125,
  h1: {
    fontWeight: 500,
    fontSize: "2.375rem",
    lineHeight: 1.368421
  },
  h2: {
    fontWeight: 500,
    fontSize: "2rem",
    lineHeight: 1.375
  },
  h3: {
    fontWeight: 500,
    lineHeight: 1.38462,
    fontSize: "1.625rem"
  },
  h4: {
    fontWeight: 500,
    lineHeight: 1.364,
    fontSize: "1.375rem"
  },
  h5: {
    fontWeight: 500,
    lineHeight: 1.3334,
    fontSize: "1.125rem"
  },
  h6: {
    lineHeight: 1.4,
    fontSize: "1rem"
  },
  subtitle1: {
    fontSize: "1rem",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    fontWeight: 400
  },
  subtitle2: {
    lineHeight: 1.32,
    fontSize: "0.875rem",
    letterSpacing: "0.1px"
  },
  body1: {
    lineHeight: 1.467,
    fontSize: "0.9375rem"
  },
  body2: {
    fontSize: "0.8125rem",
    lineHeight: 1.53846154
  },
  button: {
    lineHeight: 1.2,
    fontSize: "0.9375rem",
    letterSpacing: "0.43px"
  },
  caption: {
    lineHeight: 1.273,
    fontSize: "0.6875rem"
  },
  overline: {
    fontSize: "0.75rem",
    letterSpacing: "1px"
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px"
  },
  listTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px"
  }
}

export default typography

import ListItemButton from "@mui/material/ListItemButton"
import {styled} from "@mui/material/styles"
import {hexToRGBA} from "@src/@core/utils/hex-to-rgba"

const MenuNavLink = styled(ListItemButton)(({theme}) => ({
  width: "100%",
  marginLeft: theme.spacing(3.5),
  marginRight: theme.spacing(3.5),
  borderRadius: theme.shape.borderRadius,
  transition: "padding-left .25s ease-in-out, padding-right .25s ease-in-out",
  "& svg": {
    fill: theme.palette.text.secondary
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover
  },
  "&.active": {
    "&, &:hover": {
      boxShadow: `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
      background: `linear-gradient(72.47deg, ${
        theme.direction === "ltr"
          ? theme.palette.primary.main
          : hexToRGBA(theme.palette.primary.main, 0.7)
      } 22.16%, ${
        theme.direction === "ltr"
          ? hexToRGBA(theme.palette.primary.main, 0.7)
          : theme.palette.primary.main
      } 76.47%)`,
      "&.Mui-focusVisible": {
        background: `linear-gradient(72.47deg, ${
          theme.palette.primary.dark
        } 22.16%, ${hexToRGBA(theme.palette.primary.dark, 0.7)} 76.47%)`
      }
    },
    "& .MuiTypography-root, & svg": {
      color: `${theme.palette.common.white} !important`,
      fill: `${theme.palette.common.white} !important`
    }
  }
}))
export default MenuNavLink

import {Logout} from "../../router/RoutesEnum"

export const bottomNavigation = isAdmin => [
  {
    title: "Settings",
    icon: "solar:settings-linear",
    path: `/${Logout}`,
    action: "write",
    resource: "settings"
  }
]

const navigation = (isAdmin = false) => {
  return [{}]
}

export default navigation

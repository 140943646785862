// ** Imports createContext function
import React, {createContext, useEffect, useState} from "react"

// ** Imports createContextualCan function
import {PureAbility} from "@casl/ability"
import {createContextualCan} from "@casl/react"
import {initialAbility} from "../../configs/acl/initialAbility"
import userAbility from "../../configs/acl/userAbility"
import {useCurrentUser} from "./CurrentUserContext"

// ** Create Context
export const AbilityContext = createContext(null)

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityContextProvider = ({children}) => {
  const [ability, setAbility] = useState(new PureAbility(initialAbility))
  const currentUser = useCurrentUser()

  // Update ability on current user update
  useEffect(() => {
    setAbility(new PureAbility(currentUser?.uuid ? userAbility(currentUser) : initialAbility))
  }, [currentUser])

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

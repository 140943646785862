import {useHistory, useLocation} from "react-router-dom"
// ** MUI Imports
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import {styled} from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import ListItemButton from "@mui/material/ListItemButton"

// ** Configs Import
import themeConfig from "@configs/themeConfig"

// ** Custom Components Imports
import CanViewNavLink from "@src/layouts/components/acl/CanViewNavLink"
import Translations from "@src/layouts/components/Translations"
import UserIcon from "@src/layouts/components/UserIcon"

// ** Util Imports
import {useSettings} from "@src/@core/hooks/useSettings"
import {handleURLQueries} from "@src/@core/layouts/utils"
import MenuNavLink from "../../../../components/mui/navMenuLink"
import {hexToRGBA} from "../../../../utils/hex-to-rgba"

const MenuItemTextMetaWrapper = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && {overflow: "hidden"})
}))

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}) => {
  // ** Hooks
  const location = useLocation()
  const history = useHistory()
  // ** Vars
  const {settings} = useSettings()

  const {navCollapsed} = settings
  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

  const isNavLinkActive = () => {
    if (location.pathname === item.path || handleURLQueries(item.path)) {
      return true
    } else {
      return false
    }
  }

  const MenuNavButton = styled(ListItemButton)(({theme}) => ({
    width: "100%",
    borderRadius: "1rem",
    marginLeft: theme.spacing(3.5),
    marginRight: theme.spacing(3.5),
    transition: "padding-left .25s ease-in-out, padding-right .25s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    },
    "&.active": {
      "&, &:hover": {
        boxShadow: `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
        background: `linear-gradient(72.47deg, ${
          theme.direction === "ltr"
            ? theme.palette.primary.main
            : hexToRGBA(theme.palette.primary.main, 0.7)
        } 22.16%, ${
          theme.direction === "ltr"
            ? hexToRGBA(theme.palette.primary.main, 0.7)
            : theme.palette.primary.main
        } 76.47%)`,
        "&.Mui-focusVisible": {
          background: `linear-gradient(72.47deg, ${theme.palette.primary.dark} 22.16%, ${hexToRGBA(
            theme.palette.primary.dark,
            0.7
          )} 76.47%)`
        }
      },
      "& .MuiTypography-root, & svg": {
        color: `${theme.palette.common.white} !important`,
        "& path": {
          stroke: `${theme.palette.common.white} !important`
        }
      }
    }
  }))

  const handleNavItemClick = e => {
    if (item.onClick) {
      item.onClick()
    } else {
      if (!item.path) {
        e.preventDefault()
        e.stopPropagation()
      }
      if (item?.resourceTab) {
        window.open(item.path, "_blank")
      } else if (item?.purchaseTokens) {
        window.open(item.path, "_self")
      } else {
        history.push(item.path ? item.path : "/")
        if (navVisible) {
          toggleNavVisibility()
        }
      }
    }
  }

  return (
    <CanViewNavLink navLink={item}>
      <ListItem
        disablePadding
        className="nav-link"
        disabled={item.disabled || false}
        sx={{mt: 4, px: "0 !important"}}
      >
        {item?.type !== "filled" && (
          <MenuNavLink
            {...(item.disabled && {tabIndex: -1})}
            className={isNavLinkActive() ? "active" : ""}
            {...(item.openInNewTab ? {target: "_blank"} : null)}
            onClick={e => handleNavItemClick(e)}
            sx={{
              ...(item.navLinkStyle && item?.navLinkStyle),
              py: 2,
              ...(item.disabled ? {pointerEvents: "none"} : {cursor: "pointer"}),
              px:
                navCollapsed && !navHover
                  ? (collapsedNavWidth - navigationBorderWidth - 22 - 28) / 8
                  : 4,
              "& .MuiTypography-root, & svg": {
                color: "text.secondary"
              }
            }}
          >
            <ListItemIcon
              sx={{
                transition: "margin .25s ease-in-out",
                ...(navCollapsed && !navHover ? {mr: 0} : {mr: 2}),
                ...(parent ? {ml: 1.5, mr: 3.5} : {}),
                "& svg": {
                  fontSize: "0.625rem",
                  ...(!parent ? {fontSize: "1.375rem"} : {}),
                  ...(parent && item.icon ? {fontSize: "0.875rem"} : {}),
                  ...((item?.resourceTab || item?.purchaseTokens) && {fill: "none !important"})
                }
              }}
            >
              {icon && <UserIcon icon={icon} />}
              {item.customIcon && (
                <p
                  style={{margin: "0", display: "inherit"}}
                  dangerouslySetInnerHTML={{__html: item.customIcon}}
                />
              )}
            </ListItemIcon>

            <MenuItemTextMetaWrapper
              sx={{
                ...(isSubToSub ? {ml: 2} : {}),
                ...(navCollapsed && !navHover ? {opacity: 0} : {opacity: 1})
              }}
            >
              <Typography
                className={item?.purchaseTokens && item?.typographyClass}
                variant="subtitle1"
                {...((themeConfig.menuTextTruncate ||
                  (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                  noWrap: true
                })}
              >
                <Translations text={item.title} />
              </Typography>
              {item.badgeContent ? (
                <Chip
                  size="small"
                  label={item.badgeContent}
                  color={item.badgeColor || "primary"}
                  sx={{
                    height: 22,
                    minWidth: 22,
                    "& .MuiChip-label": {px: 1.5, textTransform: "capitalize"}
                  }}
                />
              ) : null}
            </MenuItemTextMetaWrapper>
          </MenuNavLink>
        )}

        {item?.type === "filled" && (
          <MenuNavButton
            {...(item.disabled && {tabIndex: -1})}
            className={isNavLinkActive() ? "active" : ""}
            {...(item.openInNewTab ? {target: "_blank"} : null)}
            onClick={e => handleNavItemClick(e)}
            sx={{
              ...(item.height && {height: item.height}),
              py: 2,
              ...(item.disabled ? {pointerEvents: "none"} : {cursor: "pointer"}),
              px:
                navCollapsed && !navHover
                  ? (collapsedNavWidth - navigationBorderWidth - 22 - 28) / 8
                  : 4,
              "& .MuiTypography-root, & svg": {
                color: "text.secondary"
              },
              background: item.background,
              ...(item.boxShadow && {boxShadow: item.boxShadow}),
              ...(item.border && {border: item.border}),
              ...(item.borderImage && {borderImage: item.borderImage}),
              ...(item.borderRadius && {borderRadius: item.borderRadius})
            }}
          >
            <ListItemIcon
              sx={{
                transition: "margin .25s ease-in-out",
                ...(navCollapsed && !navHover ? {mr: 0} : {mr: 2}),
                ...(parent ? {ml: 1.5, mr: 3.5} : {}),
                "& svg": {
                  fontSize: "0.625rem",
                  ...(!parent ? {fontSize: "1.375rem"} : {}),
                  ...(parent && item.icon ? {fontSize: "0.875rem"} : {}),
                  ...(item?.resourceTab && {fill: "none !important"})
                }
              }}
            >
              {icon && <UserIcon icon={icon} />}
              {item.customIcon && item.customIcon}
            </ListItemIcon>

            <MenuItemTextMetaWrapper
              sx={{
                ...(isSubToSub ? {ml: 2} : {}),
                ...(navCollapsed && !navHover ? {opacity: 0} : {opacity: 1})
              }}
            >
              <Typography
                variant="subtitle1"
                {...((themeConfig.menuTextTruncate ||
                  (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                  noWrap: true
                })}
              >
                <Translations text={item.title} />
              </Typography>
            </MenuItemTextMetaWrapper>
          </MenuNavButton>
        )}
      </ListItem>
    </CanViewNavLink>
  )
}

export default VerticalNavLink

import React, {createContext, useContext, useEffect, useState} from "react"
import {useSelector} from "react-redux"

export const CurrentUserContext = createContext({})

export const CurrentUserContextProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState({})
  const {userData} = useSelector(({auth}) => auth)
  useEffect(() => {
    setCurrentUser(userData)
  }, [userData])

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUser = () => useContext(CurrentUserContext)

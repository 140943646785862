// ** Redux Imports
import {createSlice} from "@reduxjs/toolkit"
import {isNullOrUndefined} from "../../utility/Utils"

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    conversationList: {},
    chatPagination: true,
    currentLoadedPage: 0
  },
  reducers: {
    addConversations: (state, action) => {
      state.conversationList = {...state.conversationList, ...action.payload}
    },
    setChatPagination: (state, action) => {
      state.chatPagination = action.payload
    },
    addMessagesInConversation: (state, action) => {
      const {uuid, data} = action.payload
      const conversation = state.conversationList[uuid]
      if (conversation) {
        conversation.totalMessages = data?.total_items
        if (data?.data) {
          const newMessages = {}
          if (data.data.length) {
            data.data.reverse().forEach(messageObject => {
              newMessages[messageObject.uuid] = messageObject
            })
          }
          conversation.messages = {...newMessages, ...conversation.messages}
        } else if (data?.messages) {
          const newMessages = {}
          data.messages.forEach(messageObject => {
            newMessages[messageObject.uuid] = messageObject
          })
          conversation.messages = {...conversation.messages, ...newMessages}
        }
        if (action.payload.loadedMessagePage) {
          conversation.loadedMessagePage = action.payload.loadedMessagePage
        }
        if (!isNullOrUndefined(action.payload.messagePagination)) {
          conversation.messagePagination = action.payload.messagePagination
        }
      }
    },
    removeConversation: (state, action) => {
      const uuidToDelete = action.payload
      delete state.conversationList[uuidToDelete]
    },
    setCurrentLoadedPage: (state, action) => {
      state.currentLoadedPage = action.payload
    },
    updateMessage: (state, action) => {
      const {conversationUuid, message} = action.payload
      const conversation = state.conversationList[conversationUuid]
      conversation.messages = {
        ...conversation.messages,
        [message.uuid]: message
      }
    },
    updateConversation: (state, action) => {
      const {conversationUuid, data} = action.payload
      const conversation = state.conversationList?.[conversationUuid]
      if (conversation) {
        state.conversationList[conversationUuid] = {...conversation, ...data}
      }
    },
    removeAllConversations: (state, action) => {
      state.conversationList = {}
      state.chatPagination = false
      state.currentLoadedPage = 1
    }
  }
})

export const {
  updateConversation,
  addConversations,
  setChatPagination,
  addMessagesInConversation,
  removeConversation,
  setCurrentLoadedPage,
  updateMessage,
  removeAllConversations
} = chatSlice.actions

export default chatSlice.reducer

import lazyLoader from "../../ComponentLoader"
import {Logout as path} from "../RoutesEnum"

const SecurityRoutes = [
  {
    path: `/${path}`,
    component: lazyLoader(() => import("../../views/pages/authentication/Logout")),
    layout: "BlankLayout"
  }
]

export default SecurityRoutes

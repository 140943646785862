import themeConfig from "@configs/themeConfig"
import notAuthImg from "@src/assets/images/pages/not-authorized.svg"
// ** MUI Components
import {styled} from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

// ** Layout Import
import BlankLayout from "@src/@core/layouts/BlankLayout"
import Translations from "../../../layouts/components/Translations"

// ** Styled Components
const BoxWrapper = styled(Box)(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw"
  }
}))

const Img = styled("img")(({theme}) => ({
  [theme.breakpoints.down("lg")]: {
    height: 450,
    marginTop: theme.spacing(10)
  },
  [theme.breakpoints.down("md")]: {
    height: 400
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: theme.spacing(20)
  }
}))

const NotAllowed = ({message}) => {
  return (
    <BlankLayout>
      <img
        src={themeConfig.app.appLogoFullImage}
        alt="logo"
        width="200"
        className="ml-2"
        style={{position: "absolute", top: "3.9rem"}}
      />
      <Box className="content">
        <Box
          sx={{
            mt: 5,
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <BoxWrapper sx>
            <Typography variant="h5">
              {message ?? <Translations text="Account_Disabled" />}
            </Typography>
          </BoxWrapper>
          <Img height="300" alt="error-illustration" src={notAuthImg} />
        </Box>
      </Box>
    </BlankLayout>
  )
}
export default NotAllowed

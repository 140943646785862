// ** React Imports
import {useContext} from "react"

// ** Component Imports
import {AbilityContext} from "@src/utility/context/Can"

const CanViewNavLink = props => {
  // ** Props
  const {children, navLink} = props

  // ** Hook
  const ability = useContext(AbilityContext)
  if (navLink && navLink.auth === false) {
    return <>{children}</>
  } else {
    return ability && ability.can(navLink?.action, navLink?.resource) ? <>{children}</> : null
  }
}

export default CanViewNavLink
